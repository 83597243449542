<template>      
<div class=""> 
    <featured-event></featured-event>
    <top-search></top-search>

    
    <div class="events-Container">        
        <article v-for="event in events" :key="event.id" >
        <router-link :to="`/event/${event.id}`">
                <div class="event-Card">
                <div class="event-cardImage">
                    <img v-bind:src="event.image" />
                    
                </div>

                <div class="event-cardContent">
                    
                    <span class="event-Category">{{events[0].category}}</span>
                    
                    <h2 class="subtitle-2">{{ event.name }} </h2>
                    
                    <div class="content" v-html="event.description"></div>
                    
                    
                    <div class="event-cardMeta">
                        <div class="event-cardAttribute">
                            <div class="card-eventIcon " title="Start/end time">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="clock" class="svg-inline--fa fa-clock fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm57.1 350.1L224.9 294c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h48c6.6 0 12 5.4 12 12v137.7l63.5 46.2c5.4 3.9 6.5 11.4 2.6 16.8l-28.2 38.8c-3.9 5.3-11.4 6.5-16.8 2.6z"></path></svg>
                            </div>
                            <span>{{ event.start_time }} </span>
                        </div>
                        <div class="event-cardAttribute">
                            <div class="card-eventIcon " title=""><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" class="svg-inline--fa fa-map-marker-alt fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path></svg>
                            </div>
                            <span>
                                {{ event.location }}</span>
                            <!-- </a> -->
                        </div>
                        <div class="event-cardAttribute">
                            <div class="card-eventIcon" title="Entry"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="coins" class="svg-inline--fa fa-coins fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M0 405.3V448c0 35.3 86 64 192 64s192-28.7 192-64v-42.7C342.7 434.4 267.2 448 192 448S41.3 434.4 0 405.3zM320 128c106 0 192-28.7 192-64S426 0 320 0 128 28.7 128 64s86 64 192 64zM0 300.4V352c0 35.3 86 64 192 64s192-28.7 192-64v-51.6c-41.3 34-116.9 51.6-192 51.6S41.3 334.4 0 300.4zm416 11c57.3-11.1 96-31.7 96-55.4v-42.7c-23.2 16.4-57.3 27.6-96 34.5v63.6zM192 160C86 160 0 195.8 0 240s86 80 192 80 192-35.8 192-80-86-80-192-80zm219.3 56.3c60-10.8 100.7-32 100.7-56.3v-42.7c-35.5 25.1-96.5 38.6-160.7 41.8 29.5 14.3 51.2 33.5 60 57.2z"></path></svg>
                            </div>				
                            <span>{{ event.price/1000 }}K VND</span>	
                        </div>        
                    </div>
                </div>
            </div>    
        </router-link>
        </article>
    </div>
    
</div>


</template>

<script>
import { mapState } from 'vuex';
import FeaturedEvent from '../components/FeaturedEvent.vue';
// import SideNavigation from '../components/SideNavigation.vue';
import TopSearch from '../components/TopSearch.vue';

export default {
  components: { TopSearch, FeaturedEvent },
  name: 'Home',
  mounted() {
      console.log('remounted');
      this.$store.commit('SET_CITY', this.city_slug);
      this.$store.commit('SET_CATEGORY', this.category_slug);
      this.$store.dispatch("getEvents");
  },
  data() {
    return {
        whenOptions: [
            'Today',
            'Tomorrow',
            'This Week',
            'Any Time'
        ],
        current_route: ''
    }
  },
  props: {
      city_slug: {
          type: String,
          required: false
      },
      category_slug : {
        type: String,
        required: false
      }
  },
  created() { 
  },
  methods: {
  },
  watch: {
  },
  computed: 
    // Getting mapped states from vuex
    mapState(['events', 'category_id', 'time_range']) 
}
</script>
