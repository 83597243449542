import { createStore } from "vuex" 
import { createLogger } from 'vuex'
import axios from 'axios'

const store = createStore({
   plugins: [createLogger()],

   state:{
      events: [],
      featured_events: [],
      categories: [],
      locations: [],
      category_slug: 'notset',
      time_range: 0,
      city_slug: 'notset'
   },
   actions: {
    getEvents({ commit }) {
        let params = {
            category_slug: this.state.category_slug,
            city_slug: this.state.city_slug,
            time_range: this.state.time_range
        }
        console.log(params);
        axios
            .get(`${process.env.VUE_APP_API_BASE}/events`, { params })
            .then(response => {
                commit('SET_EVENTS', response.data)
            })
    },  
    getCategories({ commit }) {
        let params = {}

        axios
            .get(`${process.env.VUE_APP_API_BASE}/categories`, { params })
            .then(response => {
                commit('INSERT_CATEGORIES', response.data);
            })
        }
    },
    mutations: {    
        SET_EVENTS(state, events) {
            state.events = events.results
            state.featured_events = events.featured
            //console.log(events.featured);
        },
        INSERT_CATEGORIES(state, results) {
            //console.log(`new categories: ${results}`);
            state.categories = results.categories
            state.locations = results.locations
        },
        INSERT_LOCATIONS(state, locations) {
            //console.log(`new locations: ${locations}`);
            state.locations = locations.results
        },
        SET_CATEGORY(state, category_slug) {
            //console.log(`new cat: ${category_slug}`);

            if(category_slug == null ) { state.category_slug = 0}
            else {
                state.category_slug = category_slug
            }
        },
        SET_TIMERANGE(state, time_range) {
            //console.log(time_range)
            state.time_range = time_range
        },
        SET_CITY(state, city) {
            state.city_slug = city
        }
    },
    
})

export default store


// import { createStore } from 'vuex'

// const store = createStore({
//   state () {
//     return {
//       events: []
//     }
//   },
//   mutations () {
//     return {
//         SET_EVENTS(state, events) {
//             state.events = events
//         }
//     }
//   },
//   actions () { return {
//     getEvents({ commit }) {
//         axios.get('https://jsonplaceholder.typicode.com/posts')
//         .then(response => {
//         commit('SET_POSTS', response.data)
//         })
//     }
//   }
//   }
  
// })

// //export store module
// export default {
//     store
// }