<template>
    <div class="event-Container">
        <div class="event-headerImage">
            <img :src="event.image">
        </div>

        <div class="event-aboutSection">
            <span class="event-Category">{{event.category}}</span>
            <h1 class="subtitle-1">{{event.name}}</h1>
            <div class="event-cardMeta">
                        <div class="event-cardAttribute">
                            <div class="card-eventIcon " title="Start/end time">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="clock" class="svg-inline--fa fa-clock fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm57.1 350.1L224.9 294c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h48c6.6 0 12 5.4 12 12v137.7l63.5 46.2c5.4 3.9 6.5 11.4 2.6 16.8l-28.2 38.8c-3.9 5.3-11.4 6.5-16.8 2.6z"></path></svg>
                            </div>
                            <span>{{ event.start_time }} </span>
                        </div>
                        <div class="event-cardAttribute">
                            <div class="card-eventIcon " title=""><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" class="svg-inline--fa fa-map-marker-alt fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path></svg>
                            </div>
                            <span>
                                {{ event.location }}</span>
                            <!-- </a> -->
                        </div>
                        <div class="event-cardAttribute">
                            <div class="card-eventIcon" title="Entry"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="coins" class="svg-inline--fa fa-coins fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M0 405.3V448c0 35.3 86 64 192 64s192-28.7 192-64v-42.7C342.7 434.4 267.2 448 192 448S41.3 434.4 0 405.3zM320 128c106 0 192-28.7 192-64S426 0 320 0 128 28.7 128 64s86 64 192 64zM0 300.4V352c0 35.3 86 64 192 64s192-28.7 192-64v-51.6c-41.3 34-116.9 51.6-192 51.6S41.3 334.4 0 300.4zm416 11c57.3-11.1 96-31.7 96-55.4v-42.7c-23.2 16.4-57.3 27.6-96 34.5v63.6zM192 160C86 160 0 195.8 0 240s86 80 192 80 192-35.8 192-80-86-80-192-80zm219.3 56.3c60-10.8 100.7-32 100.7-56.3v-42.7c-35.5 25.1-96.5 38.6-160.7 41.8 29.5 14.3 51.2 33.5 60 57.2z"></path></svg>
                            </div>				
                            <span>{{ event.price/1000 }}K VND</span>	
                        </div>        
                    </div>

            <h2 class="subtitle-2">About the event </h2>
            <p v-html="event.description"></p>

        </div>

        <div class="event-metaContainer">
           <section class="event-metaSection">
            <h2 class="subtitle-1">{{venue.name}}</h2>
            <p>{{venue.description}}</p>
           
            <a v-bind:href="venue.website">{{venue.website}}</a>
            
           </section>
            
            <section class="event-metaSection">

                <h2 class="subtitle-2">Map</h2>
                 <l-map  
                    style="height: 20vh"
                    v-model="zoom"
                    v-model:zoom="zoom"
                    :center="[marker.latitude, marker.longitude]">

                    <l-tile-layer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    ></l-tile-layer>
           
                    <l-marker :lat-lng="[marker.latitude, marker.longitude]">
                        <l-icon :icon-url="iconUrl" />
                        <l-popup>
                        lol
                        </l-popup>
                    </l-marker>
           

                </l-map>
                 <p>{{venue.address}}</p>

                 <div class="event-linkToMap">
                    <a v-bind:href="'http://maps.google.com/maps?daddr=' + venue.address + '&directionsmode=driving'" target="_blank" >Show route</a>
                </div>

            </section>
             <section class="event-metaSection">
                
                <h2 class="subtitle-2">Read more</h2>
                <ul>
                    <li><a v-bind:href="venue.facebook" target="_blank">{{venue.facebook}}</a></li>
                    <li><a v-bind:href="venue.twitter" target="_blank">{{venue.twitter}}</a></li>
                </ul>
             </section>
        </div>

       
    </div>
</template>

<script>
    import "leaflet/dist/leaflet.css"
    import {    LMap,
                LIcon,
                LTileLayer,
                LMarker,
                
                LPopup } from "@vue-leaflet/vue-leaflet";

    import {
        mapState
    } from 'vuex';

    import axios from 'axios';

    export default {
        components: {
            LMap,
            LIcon,
            LTileLayer,
            LMarker,
            
            LPopup,
                  
        },
        mounted() {
            //console.log('Event view')
            //console.log(`Route: ${this.$route.params.id}`);
            //   this.citySelected = this.$route.params.name;
        },

        data() {
            return {
                marker: {
                    latitude: 0,
                    longitude: 0
                },
                event_id: 0,
                event: {},
                venue: {},
                zoom: 14,
                iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),  
                iconUrl: require('leaflet/dist/images/marker-icon.png'),  
                shadowUrl: require('leaflet/dist/images/marker-shadow.png')  
            }
        },
        async beforeMount() {
            // HERE is where to load Leaflet components!            
        },
        created() {
            // watch the params of the route to fetch the data again
            this.$watch(
                () => this.$route.params,
                () => {
                    if (this.$route.params.id > 0) {
                        // Not needed anymore. keeping for reference
                        this.event_id = this.$route.params.id;

                        let params = {
                            event_id: this.event_id
                        }
                        //console.log('retreiving...');
                        axios
                            .get(`${process.env.VUE_APP_API_BASE}/events/${this.event_id}`, {
                                params
                            })
                            .then(response => {
                                this.event = response.data.event
                                this.venue = response.data.venue
                                this.marker.latitude = this.venue.latitude
                                this.marker.longitude = this.venue.longitude
                            });
                        // axios
                        //     .get(`${process.env.VUE_APP_API_BASE}/event`, { params })
                        //     .then(response => (this.event = response.data))
                        //console.log(this.event);
                    }
                },



                {
                    immediate: true
                }
            )
        },
        methods: {
            format_time(timestamp) {
                return new Date(timestamp).toLocaleDateString(
                    'en-gb', {
                        // year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        timeZone: 'utc'
                    }
                );
                //   return date.format('MM-DD HH');
            }
        },
        computed: {
            ...mapState(['events', 'category_id', 'time_range'])
        }
    }
</script>