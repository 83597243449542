import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/home.vue";
import About from "@/views/about.vue";
import Event from "@/views/event.vue";
import LandingPage from "@/views/landing.vue"

const routes = [ 
  { path: '/', 
    name: "Landing",
    component: LandingPage,
    props: true 
  },
  {
    path: "/:city_slug", 
    name: "Home",
    component: Home,
    props: true,
    children: [
      {
        path: ':category_slug',
        component: Home,
        props: true
      }
    ]
  },
  {
    path: "/event/:id",
    name: "Event",
    component: Event,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  }
    
];

  const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: 'is-active'
  });
  
  export default router;
