import { createApp } from 'vue'

import App from './App.vue'
import TopNavigation from './components/TopNavigation.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VCalendar from 'v-calendar';

//font awesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons'

library.add(fas, faFacebook, faTwitter);


//router
import router from "./router";

//bulma
require('@/scss/main.scss');
// require('bulma-dashboard/dist/bulma-dashboard.css');

import store from './store/index'


const app = createApp(App, {
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        'top-navigation': TopNavigation
    }
});
app.config.devtools = true

app.config.productionTip = false

app.directive("click-outside", {
    beforeMount: function (el, binding) {
        // Define ourClickEventHandler
        const ourClickEventHandler = event => {
            if (!el.contains(event.target) && el !== event.target) {
                // as we are attaching an click event listern to the document (below)
                // ensure the events target is outside the element or a child of it
                binding.value(event); // before binding it
            }
        };
        // attached the handler to the element so we can remove it later easily
        el.__vueClickEventHandler__ = ourClickEventHandler;

        // attaching ourClickEventHandler to a listener on the document here
        document.addEventListener("click", ourClickEventHandler);
    },
    unmounted: function (el) {
        // Remove Event Listener
        document.removeEventListener("click", el.__vueClickEventHandler__);
    }
})

app
    .use(store)
    .use(VCalendar)
    .use(router)
    .use(VueAxios, axios)
    .mount('#app');


