<template>
    <section class="events-TopSearch">
        <div class='topSearch-chooseCity'>

            <h1>Find Events in</h1>

            <select v-model="selectedCity" class="">
                <option  v-for="location in locations" :key="location" v-bind:value="location.id">{{location.name}}</option>
            </select>
        
        </div>
        <div class="topSearch-chooseCategory">
            
            <ul>
                <li><a @click="handleCategoryClick({slug: '', name: 'Live Music'})">All</a></li>
                <li v-for="category in categories" :key="category">
                    <a @click="handleCategoryClick(category)">{{category.name}}</a>
                </li>
            </ul>
        </div>


    </section>

    <section>
        <ul class="events-timeQuickSelect">
            <li v-for="when in whenOptions" :key="when">
                <a v-bind:class="{ 'time-shortcut--is-active': selectedInterval == when }"
                    @click="handleDateClicked(when)">{{when}}</a>
            </li>
        </ul>
    </section>
</template>

<script>
    import { useRoute } from 'vue-router';
    import { mapState } from 'vuex';

    export default {
        name: 'TopNavigation',
        components: {},

        props: {
            // propSelectedCity: String
        },
        setup() {

        },
        data() {
            return {
                selectedInterval: '',
                selectedCity: 1,
                selectedCategory: 0,
                timeInterval: new Date(),
                whenOptions: [
                    'Today',
                    'Tomorrow',
                    'This Week',
                    'Any Time'
                ],
                current_route: ''

            }
        },
        mounted() {
            const route = useRoute();
            this.current_route = route;
            console.log(route.params.city_slug);
            this.selectedCity = route.params.city_slug;
        },
        created() {

        },
        methods: {
            handleDateClicked(e) {
                this.selectedInterval = e;
                let today = new Date();
                switch (e) {
                    case this.whenOptions[0]:
                        // today
                        this.timeInterval = {
                            start: new Date(today.setDate(today.getDate())),
                            end: new Date(today.setDate(today.getDate()))
                        }
                        break;
                    case this.whenOptions[1]:
                        // tomorrow
                        this.timeInterval = {
                            start: new Date(new Date().setDate(today.getDate() + 1)),
                            end: new Date(new Date().setDate(today.getDate() + 1))
                        }
                        break;
                    case this.whenOptions[2]:
                        //this week
                        var curr = new Date; // get current date
                        var first = curr.getDate() - curr.getDay() +
                            1; // First day is the day of the month - the day of the week
                        var last = first + 6; // last day is the first day + 6

                        var firstday = new Date(curr.setDate(first)).toUTCString();
                        var lastday = new Date(curr.setDate(last)).toUTCString();

                        this.timeInterval = {
                            start: new Date(firstday),
                            end: new Date(lastday)
                        }

                        break;
                    case this.whenOptions[3]:
                        //next week
                        // var curr2 = new Date; // get current date
                        // var first2 = curr2.getDate() - curr2.getDay()+8; // First day is the day of the month - the day of the week
                        // var last2 = first2 + 6; // last day is the first day + 6

                        // var firstday2 = new Date(curr2.setDate(first2)).toUTCString();
                        // var lastday2 = new Date(curr2.setDate(last2)).toUTCString();

                        // this.timeInterval = {
                        //     start: new Date(firstday2),
                        //     end: new Date(lastday2)
                        // }  
                        //console.log('any time');
                        this.timeInterval = 0;

                        // this.$emit('updated-interval', this.timeInterval);

                        break;
                }
                // this.$store.commit('SET_TIMERANGE', this.timeInterval);
                // this.$store.dispatch("getEvents");
            },


            handleCategoryClick(category) {
                // console.log(category);
                this.selectedCategory = category.slug;
            },

        },
        watch: {
            selectedCategory() {
                this.$router.push(`/${this.selectedCity}/${this.selectedCategory}`)
                // this.$store.commit('SET_CATEGORY', this.selectedCategory?.id);
                // this.$store.dispatch("getEvents");

            },
            selectedCity() {
                this.$router.push(`/${this.selectedCity}`)
            },
            timeInterval: function () {
                this.$store.commit('SET_TIMERANGE', this.timeInterval);
                this.$store.dispatch("getEvents");
            },
            $route (to){
                this.$store.commit('SET_CITY', to.params.city_slug);
                this.$store.commit('SET_CATEGORY', to.params.category_slug);
                this.$store.dispatch("getEvents");
            }
        },
         computed: { 
            // Getting mapped states from vuex
            ...mapState(['locations', 'categories']) 
        }
    }
</script>