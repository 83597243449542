<template>
    <h1>Temporary landing page</h1>
    <p>please select a city</p>
    <router-link 
        v-for="location in locations" 
        :key="location" 
        :to="`/${location.id}`"
        >
        <p>{{location.name}}</p>
    </router-link>

</template>

<script>
import { useRoute } from 'vue-router';
import { mapState } from 'vuex';

export default {
  name: 'LandingPage',
   mounted() {
        const route = useRoute();
        this.current_route = route;
        
    },
    computed: { 
        // Getting mapped states from vuex
        ...mapState(['locations']) 
    }
}
</script>